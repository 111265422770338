import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "checkbox"]

  updateValue(evt) {
    if (this.hasFieldTarget) {
      const input = this.fieldTarget.querySelector("input")
      if (input) {
        input.value = this.checkboxTargets.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.name).join(",")
        this.dispatch("updated")
      }
    }
  }
}