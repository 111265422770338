import { Controller } from "@hotwired/stimulus"
import { HandwritingCanvas } from "../javascripts/handwriting_canvas"

export default class extends Controller {
  static targets = ["canvas", "field", "preview"]

  canvasTargetConnected(canvas) {
    this.signatureElement = new HandwritingCanvas(canvas)
    const context = canvas.getContext('2d')
    context.lineCap = 'round';
    context.lineJoin = 'round';
    context.lineWidth = 4;
    context.strokeStyle = '#333333';
  }

  canvasTargetDisconnected() {
    this.signatureElement = null
  }

  save(evt) {
    evt.preventDefault()
    if (this.hasCanvasTarget && this.hasFieldTarget) {
      const data = this.canvasTarget.toDataURL()
      this.fieldTarget.value = data
      if (this.hasPreviewTarget) {
        this.previewTarget.src = data
      }
      this.dispatch("updated")
    }
  }

  clear(evt) {
    evt.preventDefault()
    if (this.signatureElement) {
      this.signatureElement.clear()
    }
  }
}