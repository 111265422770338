import {
  Controller
} from "@hotwired/stimulus"
import Dropzone from "dropzone"
Dropzone.autoDiscover = false

export default class extends Controller {
  static targets = ["errors"]
  static values = { errorUrl: String }

  connect() {
    if (!(this.dropzone)) {
      this.dropzone = new Dropzone(this.element, {
        uploadMultiple: false,
        chunking: false,
        parallelUploads: 4,
        autoQueue: true,
        autoProcessQueue: true,
        maxFilesize: 2048000000,
        createImageThumbnails: false,
        timeout: 0,
      })
    }
    this.dropzone.on('complete', file => {
      this.dropzone.removeFile(file)
    })
    this.dropzone.on('error', (file, error) => {
      const message = `${file.name} failed to upload`
      this.recordError(error, message)
    })
  }
  disconnect() {
    if (this.dropzone) {
      this.dropzone.disable()
      this.dropzone = null
    }
  }
  async recordError(error, message) {
    this.writeErrorToPage(message)
    this.writeErrorToServer(error)
  }

  async writeErrorToPage(message) {
    if (this.hasErrorsTarget) {
      this.errorsTarget.classList.add('show')
      const p = document.createElement('p')
      this.errorsTarget.append(message, p)
    }
  }

  async writeErrorToServer(error) {
    console.log(error)
    if (this.hasErrorUrlValue) {
      const formData = new FormData()
      const message = JSON.stringify(error)
      formData.append('message', message)
      fetch(this.errorUrlValue, {
        method: 'POST',
        body: formData
      })
    }
  }
}
