import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  submit(evt) {
    evt.preventDefault()
    var allValid = true
    this.buttonTargets.forEach((button) => {
      const form = button.closest("form")
      if (form.reportValidity()) {
        button.click()
      } else {
        allValid = false
      }
    })
    if (allValid) {
      evt.target.classList.add("d-none")
      this.element.classList.add("is-updating")
      this.dispatch("submitted")
      this.element.classList.remove("is-updating")
    }
  }
}

