import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  open(evt) {
    const drawer = document.getElementById(evt.params.id)
    if (drawer) {
      evt.preventDefault();
      drawer.show()
    }
  }
}